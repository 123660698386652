import React from "react"
import { Container } from "react-bootstrap"
import Layout from "../components/LayoutTwo"
import { Helmet } from "react-helmet"

class Grazie extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Italian Art Trust O.N.L.U.S. - Cookie Policy</title>
          <meta
            name="description"
            content="This site gives an overview of the cookie policies employed by the Italian Art Trust O.N.L.U.S."
          />
        </Helmet>
        <Container>
          <section className="section" id="contatti">
            <div className="section__wrap">
              <h1 className="section__header cookie__header ">COOKIE POLICY</h1>

              <p className="cookie__text">
                {" "}
                Questo sito utilizza i cookies. I cookies sono piccoli file di
                testo che servono ad identificare l’utilizzatore di un sito.
                Vengono adoperati per facilitare la navigazione interna del sito
                stesso da parte di un visitatore, e sono memorizzati sul PC del
                visitatore.
              </p>
              <p>
                Questo sito sfrutta Google Analytics per l’analisi di utilizzo
                del sito stesso. I cookies di Google Analytics gestiscono in
                forma anonima informazioni sul comportamento dei visitatori di
                un sito e vengono trasmesse unitamente agli indirizzi IP
                direttamente a Google. Queste informazioni sono utilizzate per
                verificare l’uso del sito web e produrre statistiche
                sull’attività di www.italianartrust.com
              </p>
              <p>
                Non utilizzeremo (e non consentiremo a terzi di utilizzare) le
                statistiche così raccolte per tracciare o memorizzare
                informazioni personali (Personally Identifiable Information –
                PII) dei visitatori del nostro sito. Né noi, né Google,
                assoceremo mai le informazioni acquisite o gli indirizzi IP ad
                alcun PC o persona fisica, a meno che non vengono esplicitamente
                e volontariamente fornite tramite un apposito form sul nostro
                sito web.
              </p>
              <p>
                È possibile accettare o meno l’utilizzo dei cookies. La maggior
                parte dei browser li accetta in automatico, ma di solito è
                possibile modificarne la configurazione affinché li rifiuti.
                Disabilitando l’utilizzo dei cookies è possibile che alcune
                funzioni del nostro sito non siano disponibili.
              </p>
              <p>
                Eventuali collegamenti da e verso il nostro sito fanno capo ad
                un proprio regolamento sulla privacy che dovrebbe essere
                consultabile tramite collegamenti corrispondenti sui rispettivi
                siti web. Non ci riteniamo responsabili per i contenuti e il
                trattamento dei dati personali effettuato da tali siti web. Vi
                raccomandiamo di leggere attentamente le politiche di sicurezza
                adottate da ogni sito web prima di fornire qualsiasi
                informazione personale o effettuare transizioni di qualsiasi
                natura.
              </p>
              <p>
                Per qualsiasi chiarimento sulle informazioni sopra riportate, vi
                preghiamo di contattarci. Se desiderate non ricevere cookies dal
                nostro sito web, vi preghiamo di configurare il vostro browser
                affinché non li accetti.
              </p>
            </div>
          </section>
        </Container>
      </Layout>
    )
  }
}

export default Grazie
