import { Link } from "gatsby"
import React from "react"
import { Container, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"

const HeaderTwo = () => (
  <div>
    <Helmet>
      <title>
        Italian Art Trust O.N.L.U.S. - Finanziamento di giovani artisti
      </title>
      <meta
        name="description"
        content="Italian Art Trust O.N.L.U.S. promoziona i giovani artisti under 35 con l’obiettivo di finanziare e valorizzare la produzione artistica in Italia, promuovendone l’esposizione al pubblico e la sua diffusione. In particolare, Italian Art Trust Onlus ha l’intento di sviluppare delle iniziative di utilità sociale nei settori della promozione dell’arte e della cultura, della tutela e valorizzazione del patrimonio culturale, storico-artistico al fine di arrecare benefici anche a persone svantaggiate in ragione di condizioni fisiche, psichiche, economiche, sociali o familiari, ai sensi dell’art."
      />
      <meta name="theme-color" content="#62ff4a" />
      <meta charset="UTF-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
      />
      <title>Italian Art Trust</title>
      <script
        src="https://unpkg.com/react/umd/react.production.min.js"
        crossorigin
      ></script>
      <script
        src="https://unpkg.com/react-dom/umd/react-dom.production.min.js"
        crossorigin
      ></script>
    </Helmet>
    <header>
      <Container>
        <Row md={12}>
          <Link to="/" className="brand position-fixed">
            Italian Art Trust
          </Link>
        </Row>
      </Container>
    </header>
  </div>
)

export default HeaderTwo
